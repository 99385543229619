<template>
  <div>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          label="Selecione uma Data"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        locale="pt-BR"
        scrollable
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="modal = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="onSelecioneData"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    data: vm => ({
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu: false,
      modal: false,
      atualizar: false,
    }),
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('/')
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
      },
      onSelecioneData () {
        const [year, month, day] = this.dateFormatted.split('/')
        this.$emit('onDataSelecionada', `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`)
        // this.date = this.dateFormatted
        this.modal = false
      },
    },
  }
</script>
